<template lang="pug">
include ../pug/svg
div.player(v-if="track.file" :class="{playing:isPlaying}")
	audio(ref="audio" :id="trackId" @timeupdate="onTimeUpdate")
		source(:src="track.file" type="audio/mpeg")
	h4 {{track.title}}
		span {{track.trackArtist||album.artistName}}
	button(type="button" :aria-controls="trackId" @click="toggleAudio()" :class="{pause:isPlaying}")
		span(v-show="!isPlaying") Play
		span(v-show="isPlaying") Pause
		+svg(svg-filename="iconListen")
	div.slider
		input(ref="slider" v-model="currentSec" type="range" min="0" :max="audioDuration")
	span.timer(v-show="isPlaying" aria-label="Track position") {{currentTime}}
	span.timer(v-show="!isPlaying" aria-label="Track length") {{totalTime()}}
div.listing(v-else)
	h4 {{track.title}}
		span {{track.trackArtist||album.artistName}}
</template>

<script>
export default {
	name: 'TrackPlayer',
	emits: ['dataFromTrackPlayer'],
	props: {
		track: {
			type: Object,
			required: true
		},
		album: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			currentSec: 0,
			currentTime: '00:00', // mm:ss
			audioDuration: 0,
			audioLoaded: false,
			isPlaying: false,
		};
	},
	computed: {
		trackId() {
			return 'track' + this.track.id;
		},
	},
	methods: {
		onTimeUpdate() {
			const audio = this.$refs.audio;
			const slider = this.$refs.slider;
			
			this.currentSec = audio.currentTime;
			this.currentTime = this.secToMinSec(this.currentSec);
			
			slider.style.backgroundSize = Math.round((this.currentSec) * 100 / (audio.duration)) + '% 100%'
			
			if (this.currentSec === audio.duration) {
				// end of track
				this.isPlaying = false;
				this.notifyParentComponent();
			}
		},	
		initSlider() {
			let audio = this.$refs.audio;
			if (audio) {
				this.audioDuration = Math.round(audio.duration);
			}
		},
		secToMinSec(secs) {
			// convert sec to mm:ss
			const format = val => `0${Math.floor(val)}`.slice(-2);
			const mins = (secs % 3600) / 60;
			
			return [mins, secs % 60].map(format).join(':');
		},
		totalTime() {
			let audio = this.$refs.audio;
			if (audio) {
				return this.secToMinSec(audio.duration);
			} else {
				return '00:00';
			}
		},
		notifyParentComponent() {
			this.$emit('dataFromTrackPlayer', this.track.id, this.isPlaying);
		},
		toggleAudio() {
			let audio = this.$refs.audio;
			
			if (audio.paused) {
				audio.play();
				this.isPlaying = true;
				this.notifyParentComponent();
				
			} else {
				audio.pause();
				this.isPlaying = false;
			}			
		},
		playAudio() {
			let audio = this.$refs.audio;
			
			if (audio.paused) {
				audio.play();
				this.isPlaying = true;
			}
		},
		stopAudio() {
			let audio = this.$refs.audio;
			
			audio.pause();
			audio.currentTime = 0;
			this.isPlaying = false;
		},
	},
	mounted: function() {
		this.$nextTick(function() {
			
			if (!this.track.file) {
				// abort
				return;
			}
			
			let audio = this.$refs.audio;
			
			audio.addEventListener(
				'loadedmetadata',
				function() {
					this.initSlider();
				}.bind(this)
			);
			
			audio.addEventListener(
				'canplay',
				function() {
					this.audioLoaded = true;
				}.bind(this)
			);
		});
	},
	watch: {
		'currentSec': function() {
			// update position on slider drag
			let diff = Math.abs(this.currentSec - this.$refs.audio.currentTime);
			// throttle sync to prevent infinite loop
			if (diff > 0.01) {
				this.$refs.audio.currentTime = this.currentSec;
			}			
		},
		'track.playing': function() {
			if (!this.track.playing) {
				this.stopAudio();
				
			} else {
				this.playAudio();
			}
		},
	}
};
</script>

<style lang="scss">
	.player,
	.listing {
		position: relative;
		padding: 10px;
		min-height: 60px;
		border-radius: 6px;
		transition: .3s ease-in-out;		
		>* {
			transition: .3s ease-in-out;
		}
		h4 {
			font-size: 1.5rem;
			font-weight: 500;
			span {
				margin-top: 5px;
				padding-right: 35px; // space for timer
				font-size: 1.2rem;
			}
		}
	}
	.listing {
		cursor: not-allowed;
		border: 2px dotted #FFF;		
	}
	.player {
		border: 2px solid #FFF;
		.timer { // timers
			position: absolute;
			bottom: 10px;
			right: 10px;
			font-size: 1.2rem;
			line-height: 6px;
			+.timer { // temporarily hide
				opacity: 0;
			}
		}		
		button {
			position: absolute;
			top: 0;
			left: 0;			
			&:hover {
				opacity: 1;
			}
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			padding: 0 10px;
			background: #FFF;
			overflow: hidden;
			text-transform: uppercase;
			font-weight: 500;
			font-size: 1.4rem;
			color: $green;
			opacity: 0;
			&.play {
				
			}
			&.pause {
				padding-bottom: 15px;
			}
			span {
				min-width: 50px;
			}
		}
		.slider {
			position: absolute;
			bottom: 10px;
			left: 10px;
			display: flex;
			height: 6px;
			width: calc(100% - 55px); // space for timer
			opacity: 0;
			visibility: hidden;
			transition: .3s ease-in-out;
		}
		[type="range"] {
			-webkit-appearance: none;
			cursor: ew-resize;
			width: 100%;
			border-radius: 3px;
			background: $lightgrey;
			background-image: linear-gradient($green, $green);
			background-size: 0% 100%;
			background-repeat: no-repeat;
			&::-webkit-slider-thumb {
				-webkit-appearance: none;
				cursor: ew-resize;
				height: 6px;
				width: 6px;
				border-radius: 3px;
				opacity: 0;
			}
			&::-webkit-slider-runnable-track  {
				-webkit-appearance: none;
				box-shadow: none;
				border: none;
				background: transparent;
			}
		}
		&.playing {
			background: $white;
			.slider {
				opacity: 1;
				visibility: visible;
			}
			h4 {
				color: $green;
				span {
					opacity: 0;
				}
			}
		}
		@media (pointer: coarse) {
			.slider {
				bottom: 8px;
				height: 10px;
			}
			[type="range"] {
				border-radius: 5px;
			}
		}		
	}
</style>